<template>
  <b-card title="Detail Bundle">
    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <span>Bundle Name <span style="color:red">(*)</span></span>
            <b-form-input
              v-model="name"
              type="text"
              placeholder="Enter Bundle Name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <span>Status</span>
            <b-form-input
              v-model="handlingBundle"
              type="text"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <span>Distribution Channel</span>
            <b-form-select v-model="selectChannel" @click="getChannel">
              <option
                v-for="(selectOption, indexOpt) in listChannel"
                :key="indexOpt"
                :value="selectOption"
              >
                {{ selectOption.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group>
            <span>Item Model <span style="color:red">(*)</span></span>
            <b-form-select
              v-model="selectItem"
              :options="getItemChannel"
              :disabled="getItemChannel.length === 0"
            >
            </b-form-select>
            <strong v-if="getItemChannel.length === 0" style="color:red;"
              >Please Select Distribution Channel first</strong
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group>
            <span>Quantity <span style="color:red">(*)</span></span>
            <b-form-input
              type="number"
              placeholder="Quantity"
              required
              v-model="selectQuantity"
              :disabled="getItemChannel.length === 0"
            >
            </b-form-input>
            <strong v-if="getItemChannel.length === 0" style="color:red;"
              >Please Select Distribution Channel first</strong
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-button
            @click="addItemBundle"
            variant="outline-success"
            :disabled="getItemChannel.length === 0"
            style="margin-top:25px !important;"
          >
            Add Item
          </b-button>
        </b-col>

        <b-col cols="12" class="mt-2 table-responsive" >
          <strong v-if="items.length === 0" style="color:red;"
            >Item List Required. Please Add Item Model first</strong
          >
          <b-table :items="items" :fields="fields" show-empty>
            <template #cell(actions)="row">
              <b-button
                size="sm"
                @click="removeItem(row)"
                class="mr-1"
                variant="danger"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-button
            @click="doSaveBundle"
            :disabled="handlingBundle === 'Inactive'"
            variant="success"
          >
            Save Bundle
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";

export default {
  data() {
    return {
      fields: [
        { key: "itemModel", label: "Model" },
        { key: "price", label: "Price" },
        { key: "quantity", label: "Quantity" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      priceMaster: [],
      name: "",
      status: "",
      selectItem: {},
      selectQuantity: 1,
      selectChannel: {},
      listChannel: [],
      totalPrice: 0,
    };
  },
  mounted() {
    this.detailBundle(this.$route.params.bundleId)
      .then((data) => {
        this.name = data.name;
        this.selectChannel = data.channel;
        this.items = data.items;
        this.items.map((x) => {
          return (x.price = x.price.$numberDecimal);
        });
        this.totalPrice = parseFloat(data.totalPrice.$numberDecimal);
      })
      .catch((e) => {
        console.log(e);
      });

    this.getItems({ status: "Active" });
    //temporary get api distchan from axios
    axios
      .get("sales-and-purchase/drcc/pcode-distchan")
      .then((response) => {
        this.listChannel = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });

    //temporary get api price from axios
    axios
      .get("sales-and-purchase/drcc/multi-price")
      .then((response) => {
        this.priceMaster = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    ...mapActions({
      getItems: "item/getItems",
      detailBundle: "item/detailBundle",
      updateBundle: "item/updateItemBundle",
    }),
    addItemBundle() {
      let { itemModel, _id, price } = this.selectItem;
      let quantity = this.selectQuantity;
      var check = this.items.find((x) => {
        return x.itemModel === itemModel;
      });
      if (check != undefined) {
        this.$bvToast.toast(`Item Already Exists`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      } else {
        this.items.push({
          itemModel,
          id: _id,
          price: price,
          quantity: quantity,
        });
      }

      this.selectItem = {};
      this.selectQuantity = 1;
      this.totalPrice += parseFloat(price) * quantity;
    },
    removeItem(row) {
      let { itemModel, price, quantity } = row.item;

      let prevLength = this.items.length;

      this.items = this.items.filter((i) => {
        return !(i.itemModel === itemModel);
      });

      let nowLength = this.items.length;

      if (nowLength < prevLength) {
        this.totalPrice -= parseFloat(price) * quantity;
      }
    },
    doSaveBundle() {
      let { name, selectChannel, totalPrice, items } = this;
      let channel = selectChannel._id;

      this.updateBundle({
        id: this.$route.params.bundleId,
        name,
        channel,
        totalPrice,
        items,
      })
        .then((data) => {
          this.$bvToast.toast("Update Bundle Success", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((e) => {
          this.$bvToast.toast("Update Bundle Fail", {
            title: "Success",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapState({
      itemCatalogue: (state) => {
        return state.item.items.map((i) => {
          return Object.assign({
            value: i,
            text: `${i.description}(${i.itemModel})`,
          });
        });
      },
    }),
    handlingBundle() {
      let mapItemStatus = [];

      this.items.forEach((element) => {
        mapItemStatus.push(element.id.status);
      });

      //temporary inactive item will show N or A value
      var status = "Active";
      if (mapItemStatus.includes("N") || mapItemStatus.includes("A")) {
        status = "Inactive";
      }

      return status;
    },
    getItemChannel() {
      var catalog = this.itemCatalogue;
      var channel = this.getChannel;
      var result = [];
      catalog.map((b) => {
        channel.map((c) => {
          //filter by selected distribution channel
          if (c.item._id === b.value._id) {
            result.push(b);
          }
          //change price by distribution channel
          result.map((y) => {
            if (c.item._id === y.value._id) {
              y.value.price = c.price.$numberDecimal;
            }
          });
        });
      });
      return result;
    },
    getChannel() {
      var filterItem = this.priceMaster.filter((x) => {
        return x.channel._id === this.selectChannel._id;
      });
      return filterItem;
    },
  },
};
</script>
